import axios from "axios";
import { toast } from "react-toastify";

export const leaveTicket = ({id, title, message, username, contacts}: any) =>
axios
  .post(
    "https://d5det9c0b2q4lhdmdo7c.apigw.yandexcloud.net/api/v0/67470ee9-d4c3-430b-9a8a-7b3ceeb6374a/a5b555a2-e18b-4fdb-bcd9-31d4f69f96a4/tickets",
    { user_id: id, title, first_message: message, username, contacts },
    {
      headers: {
        " x-api-key": "cc19f955-b6d7-42ec-bb8c-6402e32fb709",
      },
    }
  )
  .then((response) => {
    if (response.status === 200) {
      toast("Ваше сообщение отправлено. Спасибо за обратную связь!");
    }
  })
  .catch((error) => {
    toast.error("Что-то пошло не так, извините. Попробуйте ещё раз.");
  });
